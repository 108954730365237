<template lang="pug">
	//- Timeline
	//- 	TimelineItem
	//- 		p.time 2019-12-09
	//- 		p.content
	//- 			ol
	//- 				li 增加消息提示语
	//- 				li 接口增加登陆校验
	//- 	TimelineItem
	//- 		p.time 2019-12-05
	//- 			p.content
	//- 				ol
	//- 					li 增加菜单树
	//- 					li 动态增加breadcrumb
	//- 	TimelineItem
	//- 		p.time 2019-11-28
	//- 			p.content
	//- 				ol
	//- 					li 初始化前端代码
	//- 					li 增加pug依赖
	//- 					li 搭建前端基础框架
	//- 					li 前端增加多环境配置
	//- 	TimelineItem
	//- 		p.time 2019-11-25
	//- 		p.content
	//- 			ol
	//- 				li 初始化项目
	//- 				li 增加基础代码
	//- 				li 增加自动生产代码模板

</template>

<script>
	import Timeline from "view-design/src/components/timeline/timeline";
	import TimelineItem from "view-design/src/components/timeline/timeline-item";
	export default {
		components: {TimelineItem, Timeline},
		data: function () {
			return {
			}
		},
	}
</script>

<style scoped>
	.time{
		font-size: 14px;
		font-weight: bold;
	}
	.content{
		padding-left: 5px;
	}
</style>
